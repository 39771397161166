
.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  visibility: visible;
  opacity: 1;
  overflow: hiden;
  transition: 0.64s ease-in-out;
}
.popupInner {
  position: relative;
  /* bottom: -100vw;
  right: -100vh; */
  margin-top: 5%;
  display: flex;
  align-items: center;
  max-width: 800px;
  max-height: 600px;
  width: 60%;
  height: 80%;
  background-color: #fff;
  transition: 0.64s ease-in-out;
}
.popupPhoto {
  display: flex;
  width: 40%;
  height: 100%;
  overflow: hidden;
}
.popupPhoto img {
  width: auto;
  height: 100%;
}
.popupText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  height: 100%;
  padding: 4rem;
}
.popupText h1 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-transform: uppercase;
  color: #4f9050;
}
.popupText p {
  font-size: 0.875rem;
  color: #686868;
  line-height: 1.5;
}
.popup:target {
  visibility: visible;
  opacity: 1;
}
.popup:target .popup-inner {
  bottom: 0;
  right: 0;
  transform: rotate(0);
}
.popupClose {
  position: absolute;
  right: -1rem;
  top: -1rem;
  width: 3rem;
  height: 3rem;
  font-size: 0.875rem;
  font-weight: 300;
  border-radius: 100%;
  background-color: #0A0A0A;
  z-index: 4;
  color: #fff;
  line-height: 3rem;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}