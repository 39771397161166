
.quotes {
    display: flex;
    align-items: center;
    min-height: 83vh;
    height: auto;
  }
  
  .slide {
    padding: 1.5rem;
    background-color: #fdfbfbe0;
    box-shadow: 4px 5px 4px 1px #d5d5d5b0;
    overflow: visible;
    min-height: 430px;
    display: flex;
    min-width: auto;
    align-items: center;

  }
  
  .quotes-slideactive {
     padding: 2rem;
    background-color: white;
    box-shadow: 4px 5px 4px 1px #d5d5d5b0;

  }
  blockquote {
    margin: 0;
  }
  cite{
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
  }
  cite::before {
      content: '\2014 \0020';
  }
  .icon {
    width: 29px;
    height: 28px;
    fill: rgb(0 123 255 / 33%);
  }