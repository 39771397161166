.inputgroup{
  display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 18px;
}

.inputform{
  width: 100%;
    height: 50px;
    border-radius: 4px;
    padding: 5px 10px;
    border: 1px solid #00000075;
}

.formdiv{
    width: 100%;
    padding: 5% 1%;
    margin: auto;
    border: 1px solid #00000052;
    border-radius: 5px;
}

@media (max-width: 700px) {
  .formdiv{
    border:none
  }
}

.textarea{
  width: 100%;
  min-height: 100px;
}

.formlabel{
  font-size: 1.1rem;
    font-weight: 400;
    text-transform: capitalize;
}

.formselect{
  border: none!important;
  padding: 0!important;
  display: block;
}

.formbutton{
  margin-top: 30px;
  width: 100%;
  height: 43px;
  background-color: #5aa6a6;
  border: 1px solid #5aa6a6;
  border-radius: 3px;
  color: white;

}