.other {
  position: relative;
  padding: 5px;
}




.unSplash {
  margin-top: 15vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.other::before {
  /* background-image: url("./git.png") no-repeat center center/cover; */
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.68;
  left: 0px;
  top: 0px;
}
.other h1 {
  margin: 5px;
  font-size: 30px;
  color: white;
  font-weight: bolder;
  /* margin-left: 80px; */
}
.other p {
  margin: 4px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  /* margin-left: 80px; */
}
.other p a {
  color: white;
  font-weight: bolder;
}

.other form {
  margin-bottom: 4px;
  margin-top: 20px;
}
.search input[type="search"] {
  width: 100%;
  border: white;
  height: 50px;
  border-radius: 5px;
  padding: 20px;
  outline: black !important;
}

.search input[type="search"]:active {
  color: white;
}

.parentsearch {
  /* background-color: black; */
  height: 400px;
  background: url("https://i.redd.it/0r32ekavufd51.png") no-repeat center
    center/cover !important;
}

.searchbox {
  text-align: center;
}
.Gallery {
  margin-top: 3vh;
  margin-left: 1vh;
  margin-right: 1vh;
  margin-bottom: 3vh;
}
